<template>
  <div
    v-if="value && (isMobile || isTablet)"
    ref="talk"
    class="chat-talk"
    :style="talkStyle"
  >
    <chat-talk-body ref="talkBody" :talk-id="talkId" />
  </div>
  <div
    v-else-if="value"
    ref="talk"
    v-draggable="draggableConf"
    class="chat-talk"
    :style="talkStyle"
    @moved="onMoved"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <chat-talk-body ref="talkBody" :talk-id="talkId" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ChatTalkBody from './ChatTalk/ChatTalkBody'

export default {
  name: 'ChatTalk',
  components: {
    ChatTalkBody
  },
  props: {
    value: {
      type: Boolean
    },
    talkId: {
      type: [Number, String],
      default: null,
      required: true
    }
  },
  data () {
    return {
      busy: false,
      deleteBusy: 0,
      allMessages: [],
      initialLeft: 0,
      initialTop: 0,
      currentLeft: 0,
      currentTop: 0,
      draggableHandle: null,
      mouseOver: false
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      getChatOpen: 'chat/getChatOpen',
      deviceInfo: 'device'
    }),
    ...mapFields({
      loadingEnable: 'loadingEnable'
    }),
    talkData () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat && chat.talk) {
          return chat.talk
        }
      }
      return {}
    },
    talkCustomer () {
      return this.talkData?.user
    },
    messages () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat && chat.messages) {
          return chat.messages
        }
      }
      return []
    },
    pagination () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        return (chat && chat.pagination) || {}
      }
      return false
    },
    draggableConf () {
      return (this.isMobile || this.isTablet)
        ? {}
        : {
            handle: this.draggableHandle,
            resetInitialPos: true,
            initialPosition: {
              left: this.currentLeft || this.initialLeft,
              top: this.currentTop || this.initialTop
            }
          }
    },
    currentState () {
      const current = this.getChatOpen(this.talkId)
      return current?.state || false
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isTablet () {
      return this.deviceInfo?.type === 'tablet' || false
    },
    talkStyle () {
      let style
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat) {
          style = `z-index: ${1050 + parseInt(chat.lift)}; `
        }
      }
      style = `z-index: ${this.mouseOver ? 1051 : 1050}; `
      if (this.isMobile || this.isTablet) {
        style += 'max-height: 100svh; height: 100svh; '
        style += 'left: ' + (this.currentLeft || this.initialLeft) + 'px; '
        style += 'top: 0; '
      }
      return style
    }
  },
  watch: {
    currentState (n) {
      if (n) {
        this.prepareInitPos()
        this.$nextTick(() => {
          this.draggableHandle = this.$refs.talkBody?.$refs.chatTalkHeader
          this.$forceUpdate()
        })
      } else {
        this.draggableHandle = undefined
        this.currentLeft = 0
        this.currentTop = 0
      }
    }
  },
  beforeDestroy () {
    this.$nextTick(() => {
      if (this.currentState) {
        this.toggleChat(this.talkId)
        this.currentLeft = 0
        this.currentTop = 0
      }
    })
  },
  beforeMount () {
    this.prepareInitPos()
  },
  mounted () {
    this.$nextTick(() => {
      this.draggableHandle = this.$refs.talkBody?.$refs.chatTalkHeader
      this.$forceUpdate()
    })
  },
  methods: {
    ...mapActions({
      call: 'repos/call',
      flush: 'repos/flush',
      flushError: 'repos/flushError',
      loadChatMessages: 'chat/load',
      storeChatMessage: 'chat/store',
      reloadChat: 'chat/update',
      deleteChatMessages: 'chat/delete',
      toggleChat: 'chat/toggle',
      liftChat: 'chat/lift'
    }),
    onClose () {
      this.toggleChat(this.talkId)
      this.currentLeft = 0
      this.currentTop = 0
    },
    async onLoad (page) {
      try {
        this.busy = true
        this.loadingEnable = false
        if (this.talkId) {
          await this.loadChatMessages({
            talkId: this.talkId,
            page
          })
        }
      } finally {
        this.loadingEnable = true
        this.busy = false
      }
    },
    async onSend (payload) {
      const formData = new FormData()
      formData.append('message', payload.message)
      if (payload.media && payload.media.length) {
        formData.append('media', payload.media[0], 'media.jpg')
      }
      try {
        this.busy = true
        this.loadingEnable = false
        await this.storeChatMessage({
          talkId: this.talkId,
          message: formData
        })
        await this.loadChatMessages({
          talkId: this.talkId
        })
      } finally {
        this.loadingEnable = true
        this.busy = false
      }
    },
    onDelete (messageId) {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id: messageId }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            this.flushError()
            try {
              this.deleteBusy = messageId
              this.loadingEnable = false
              await this.deleteChatMessages({
                talkId: this.talkId,
                messageId
              })
            } finally {
              this.loadingEnable = true
              this.deleteBusy = 0
            }
          }
        })
    },
    onMoved (pos) {
      this.currentLeft = pos.currentDragPosition.left
      this.currentTop = pos.currentDragPosition.top
    },
    onMouseEnter () {
      this.mouseOver = true
      this.liftChat(this.talkId)
    },
    onMouseLeave () {
      this.mouseOver = false
    },
    prepareInitPos () {
      this.initialLeft = this.isMobile ? 0 : 55
      this.initialTop = 0
      // detect left
      Object.keys(this.$parent.$refs).forEach((ref) => {
        const el = this.$parent.$refs[ref]
        if (el && el.length && el[0].$refs && el[0].$refs.talk) {
          const rect = el[0].$refs.talk.getBoundingClientRect()
          if (this.initialLeft < rect.left + el[0].$refs.talk.clientWidth) {
            this.initialLeft = rect.left + el[0].$refs.talk.clientWidth
          }
          // console.log('initialLeft', el[0].$refs.talk, this.initialLeft, rect.left, el[0].$refs.talk.clientWidth)
        }
      })
      if (this.initialLeft > window.innerWidth) {
        this.initialLeft = 0
      }
      // detect top
      if (window) {
        const elHeight = window.innerHeight > 605 ? 605 : window.innerHeight
        this.initialTop = window.innerHeight - elHeight
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
